<template>
  <div class="pz_main">
    <div class="w_1000">
      <div class="banner">
        <div id="slideBox" class="slideBox">
          <div class="bd">
            <ul>
              <li id="banner1" style="display: list-item;">
                <a>
                  <img src="@/assets/upload/images/2018-08-10/首页幻灯片16228975.png" width="1000" height="374">
                </a>
              </li>
              <li id="banner2" style="display: none;">
                <a>
                  <img src="@/assets/upload/images/2018-08-10/首页幻灯片32120770.png" width="1000" height="374">
                </a>
              </li>
              <li id="banner3" style="display: none;">
                <a>
                  <img src="@/assets/upload/images/2018-08-10/首页幻灯片26302812.png" width="1000" height="374">
                </a>
              </li>
            </ul>
          </div>
          <!-- 下面是前/后按钮代码，如果不需要删除即可 -->
          <a class="prev" href="javascript:void(0)"></a>
          <a class="next" href="javascript:void(0)"></a>

        </div>
<!--        <script type="text/javascript">-->
<!--          jQuery(".slideBox").slide({ mainCell: ".bd ul", autoPlay: true });-->
<!--        </script>-->
      </div>
      <div class="c_1000_3">
        <div class="l_331">
          <a href="/products/mseries">
            <img src="@/assets/upload/images/2018-08-13/6366977395762546232881772.jpg" width="331" height="249" title="未标题-1.jpg">
          </a>
        </div>
        <div class="l_330">
          <a href="/products/nseries">
            <img src="@/assets/upload/images/2018-08-13/6366977468405030833936762.jpg" width="330" height="249" title="首页产品小图1.jpg">
          </a>
        </div>
        <div>
          <a href="/products/soft">
            <img src="@/assets/upload/images/2018-08-30/soft.jpg" width="328" height="249" title="首页产品小图1.jpg">
          </a>
        </div>
      </div>
      <div class="c_1000_4">
        <div class="z1">
          <div class="z_1">
            <a href="/products/mseries">
              <img src="@/assets/images/a1_03.png" width="69" height="79">
            </a>
          </div>
          <div class="z_2">
            <a href="/products/mseries">为您提供净水方案</a>
          </div>
        </div>
          <div class="z1">
            <div class="z_1">
              <a href="/products/mseries">
                <img src="@/assets/images/a2_03.png" width="69" height="79">
              </a>
            </div>
            <div class="z_2">
              <a href="/products/mseries">为您安装净水器</a>
            </div>
          </div>
          <div class="z1">
            <div class="z_1">
              <a href="/products/mseries">
                <img src="@/assets/images/a3_03.png" width="69" height="79">
              </a>
            </div>
            <div class="z_2">
              <a href="/products/mseries">为您检测净水水质</a>
            </div>
          </div>
          <div class="z1">
            <div class="z_1">
              <a href="/products/mseries">
                <img src="@/assets/images/a4_03.png" width="69" height="79">
              </a>
            </div>
            <div class="z_2">
              <a href="/products/mseries">为您更换滤芯</a>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script setup>


import { onMounted } from 'vue'


onMounted(() => {
  let banner_id = 2
  setInterval(()=>{

    document.querySelector('#banner' + banner_id).style.display = 'list-item'


    for(let i = 1; i <4; i++){
      if(i != banner_id){
        document.querySelector('#banner' + i).style.display = 'none'
      }
    }
    banner_id++
    if(banner_id > 3){
      banner_id = 1
    }
  },3000)
})

</script>

<style scoped>

</style>
