<template>
  <div class="w_0100">
    <p style="font-size: 16px;">Copyright 2017-{{ thisyear }} 上海劳达斯卫浴有限公司 版权所有</p>

  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import { computed } from 'vue'


const thisyear = computed(() => dayjs(new Date()).format('YYYY'))

</script>

<style scoped>

</style>
