<template>
  <div class="w_1000">
    <div class="c_1000_1">
      <div class="logo">
        <a href="/">
          <img src="@/assets/images/logo.jpg" width="364" height="54">
        </a>
      </div>
      <div class="r_315">
        <div class="hy">
<!--          <script src="/Member/status.aspx"></script>-->
        </div>
        <div class="z1">
          <p>
            <strong>
              <span style="FONT-SIZE: 14px">&nbsp;</span>
            </strong>
            <a href="https://detail.tmall.com/item.htm?spm=a1z10.3-b.w4011-13536367366.29.mCdiQ7&amp;id=44318435316&amp;rn=8cbde4e426df758611c818397c8e134e&amp;abbucket=13" target="_blank" textvalue="天猫商城">
              <strong><span style="FONT-SIZE: 14px">天猫商城</span></strong>
            </a>
            <a href="https://mall.jd.com/index-52536.html" target="_blank" textvalue="京东商城">
            <strong>
              <span style="FONT-SIZE: 14px">京东商城</span>
            </strong>
          </a>
          </p>
        </div>
        <div class="z2"> <p>400-779-7779</p></div>
      </div>
    </div>
  </div>
  <div class="w_1000">
    <div class="c_1000_2">
      <div class="menu">
        <ul id="nav">

          <li class="nli"><span><a href="/">首页</a></span></li>

          <li class="nli"><span><a href="/aboutus">关于我们</a></span>
            <ul class="sub">

            </ul>
          </li>

          <li class="nli"><span><a href="/products">产品中心</a></span>
            <ul class="sub">

            </ul>
          </li>

          <li class="nli"><span><a href="/showcase">工程案例</a></span>
            <ul class="sub">

            </ul>
          </li>

          <li class="nli">
            <span>
              <a href="/service" @mouseover="showSubMenu">服务支持</a>
            </span>
            <div v-if="subMenuShow" >
              <ul class="sub">

                <li><a href="/login.aspx">会员中心</a></li>

                <li><a href="/page/10/">常见问题Q&A</a></li>

              </ul>
            </div>

          </li>

          <li class="nli"><span><a href="/contactus">联系我们</a></span>
            <ul class="sub" style="display: none;">
            </ul>
          </li>
        </ul>
      </div>
<!--      <script>-->
<!--        jQuery(".menu").slide({ type: "menu", titCell: ".nli", targetCell: ".sub", effect: "slideDown", delayTime: 300, triggerTime: 0, defaultPlay: false, returnDefault: true });-->
<!--      </script>-->
<!--      <form action="/s.aspx"><input type="hidden" value="2" name="id">-->
<!--        <div class="ss">-->
<!--          <div class="ss_1"><input name="key" type="text" class="wb2" value="请输入搜索内容" onfocus="this;value=''"></div>-->
<!--          <div class="ss_2"><input type="image" src="@/assets/images/sse_03.jpg" width="20" height="20"></div>-->
<!--        </div>-->
<!--      </form>-->
    </div>
  </div>
</template>

<script setup>
  // name: WebHeader
  let subMenuShow = true
  function showSubMenu () {
    subMenuShow = true
  }
  function hideSubMenu () {
    subMenuShow = false
  }
</script>

<style scoped>

</style>
